// @ts-nocheck

import locale__app_i18n_src_de_DE_js from "../i18n/src/de-DE.js" assert { type: "js" };
import locale__app_i18n_src_en_US_js from "../i18n/src/en-US.js" assert { type: "js" };
import locale__app_i18n_src_fr_FR_js from "../i18n/src/fr-FR.js" assert { type: "js" };
import locale__app_i18n_src_sv_SE_js from "../i18n/src/sv-SE.js" assert { type: "js" };
import locale__app_i18n_src_et_EE_js from "../i18n/src/et-EE.js" assert { type: "js" };
import locale__app_i18n_src_it_IT_js from "../i18n/src/it-IT.js" assert { type: "js" };
import locale__app_i18n_src_nl_NL_js from "../i18n/src/nl-NL.js" assert { type: "js" };
import locale__app_i18n_src_es_ES_js from "../i18n/src/es-ES.js" assert { type: "js" };
import locale__app_i18n_src_pt_PT_js from "../i18n/src/pt-PT.js" assert { type: "js" };
import locale__app_i18n_src_cs_CZ_js from "../i18n/src/cs-CZ.js" assert { type: "js" };
import locale__app_i18n_src_sk_SK_js from "../i18n/src/sk-SK.js" assert { type: "js" };
import locale__app_i18n_src_hu_HU_js from "../i18n/src/hu-HU.js" assert { type: "js" };
import locale__app_i18n_src_pl_PL_js from "../i18n/src/pl-PL.js" assert { type: "js" };
import locale__app_i18n_src_hr_HR_js from "../i18n/src/hr-HR.js" assert { type: "js" };
import locale__app_i18n_src_sl_SI_js from "../i18n/src/sl-SI.js" assert { type: "js" };
import locale__app_i18n_src_lt_LT_js from "../i18n/src/lt-LT.js" assert { type: "js" };
import locale__app_i18n_src_lv_LV_js from "../i18n/src/lv-LV.js" assert { type: "js" };
import locale__app_i18n_src_fi_FI_js from "../i18n/src/fi-FI.js" assert { type: "js" };
import locale__app_i18n_src_da_DK_js from "../i18n/src/da-DK.js" assert { type: "js" };
import locale__app_i18n_src_no_NO_js from "../i18n/src/no-NO.js" assert { type: "js" };
import locale__app_i18n_src_bg_BG_js from "../i18n/src/bg-BG.js" assert { type: "js" };
import locale__app_i18n_src_el_GR_js from "../i18n/src/el-GR.js" assert { type: "js" };

export const localeCodes = [
  "de-DE",
  "en-US",
  "fr-FR",
  "sv-SE",
  "et-EE",
  "it-IT",
  "nl-NL",
  "es-ES",
  "pt-PT",
  "cs-CZ",
  "sk-SK",
  "hu-HU",
  "pl-PL",
  "hr-HR",
  "sl-SI",
  "lt-LT",
  "lv-LV",
  "fi-FI",
  "da-DK",
  "no-NO",
  "bg-BG",
  "el-GR",
  "lb-LU"
];

export const localeMessages = { 
  "de-DE": [
      { key: "../i18n/src/de-DE.js", load: () => Promise.resolve(locale__app_i18n_src_de_DE_js), cache: true },
  ],
  "en-US": [
      { key: "../i18n/src/en-US.js", load: () => Promise.resolve(locale__app_i18n_src_en_US_js), cache: true },
  ],
  "fr-FR": [
      { key: "../i18n/src/fr-FR.js", load: () => Promise.resolve(locale__app_i18n_src_fr_FR_js), cache: true },
  ],
  "sv-SE": [
      { key: "../i18n/src/sv-SE.js", load: () => Promise.resolve(locale__app_i18n_src_sv_SE_js), cache: true },
  ],
  "et-EE": [
      { key: "../i18n/src/et-EE.js", load: () => Promise.resolve(locale__app_i18n_src_et_EE_js), cache: true },
  ],
  "it-IT": [
      { key: "../i18n/src/it-IT.js", load: () => Promise.resolve(locale__app_i18n_src_it_IT_js), cache: true },
  ],
  "nl-NL": [
      { key: "../i18n/src/nl-NL.js", load: () => Promise.resolve(locale__app_i18n_src_nl_NL_js), cache: true },
  ],
  "es-ES": [
      { key: "../i18n/src/es-ES.js", load: () => Promise.resolve(locale__app_i18n_src_es_ES_js), cache: true },
  ],
  "pt-PT": [
      { key: "../i18n/src/pt-PT.js", load: () => Promise.resolve(locale__app_i18n_src_pt_PT_js), cache: true },
  ],
  "cs-CZ": [
      { key: "../i18n/src/cs-CZ.js", load: () => Promise.resolve(locale__app_i18n_src_cs_CZ_js), cache: true },
  ],
  "sk-SK": [
      { key: "../i18n/src/sk-SK.js", load: () => Promise.resolve(locale__app_i18n_src_sk_SK_js), cache: true },
  ],
  "hu-HU": [
      { key: "../i18n/src/hu-HU.js", load: () => Promise.resolve(locale__app_i18n_src_hu_HU_js), cache: true },
  ],
  "pl-PL": [
      { key: "../i18n/src/pl-PL.js", load: () => Promise.resolve(locale__app_i18n_src_pl_PL_js), cache: true },
  ],
  "hr-HR": [
      { key: "../i18n/src/hr-HR.js", load: () => Promise.resolve(locale__app_i18n_src_hr_HR_js), cache: true },
  ],
  "sl-SI": [
      { key: "../i18n/src/sl-SI.js", load: () => Promise.resolve(locale__app_i18n_src_sl_SI_js), cache: true },
  ],
  "lt-LT": [
      { key: "../i18n/src/lt-LT.js", load: () => Promise.resolve(locale__app_i18n_src_lt_LT_js), cache: true },
  ],
  "lv-LV": [
      { key: "../i18n/src/lv-LV.js", load: () => Promise.resolve(locale__app_i18n_src_lv_LV_js), cache: true },
  ],
  "fi-FI": [
      { key: "../i18n/src/fi-FI.js", load: () => Promise.resolve(locale__app_i18n_src_fi_FI_js), cache: true },
  ],
  "da-DK": [
      { key: "../i18n/src/da-DK.js", load: () => Promise.resolve(locale__app_i18n_src_da_DK_js), cache: true },
  ],
  "no-NO": [
      { key: "../i18n/src/no-NO.js", load: () => Promise.resolve(locale__app_i18n_src_no_NO_js), cache: true },
  ],
  "bg-BG": [
      { key: "../i18n/src/bg-BG.js", load: () => Promise.resolve(locale__app_i18n_src_bg_BG_js), cache: true },
  ],
  "el-GR": [
      { key: "../i18n/src/el-GR.js", load: () => Promise.resolve(locale__app_i18n_src_el_GR_js), cache: true },
  ],
  "lb-LU": [
      { key: "../i18n/src/fr-FR.js", load: () => Promise.resolve(locale__app_i18n_src_fr_FR_js), cache: true },
  ],
}

export const vueI18nConfigs = [
]

export const nuxtI18nOptions = {
  "experimental": {},
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "de-DE",
      "iso": "de-DE",
      "name": "Deutsch",
      "files": [
        "i18n/src/de-DE.js"
      ]
    },
    {
      "code": "en-US",
      "iso": "en-US",
      "name": "English",
      "files": [
        "i18n/src/en-US.js"
      ]
    },
    {
      "code": "fr-FR",
      "iso": "fr-FR",
      "name": "Français",
      "files": [
        "i18n/src/fr-FR.js"
      ]
    },
    {
      "code": "sv-SE",
      "iso": "sv-SE",
      "name": "Svenska",
      "files": [
        "i18n/src/sv-SE.js"
      ]
    },
    {
      "code": "et-EE",
      "iso": "et-EE",
      "name": "Eesti",
      "files": [
        "i18n/src/et-EE.js"
      ]
    },
    {
      "code": "it-IT",
      "iso": "it-IT",
      "name": "Italiano",
      "files": [
        "i18n/src/it-IT.js"
      ]
    },
    {
      "code": "nl-NL",
      "iso": "nl-NL",
      "name": "Nederlands",
      "files": [
        "i18n/src/nl-NL.js"
      ]
    },
    {
      "code": "es-ES",
      "iso": "es-ES",
      "name": "Español",
      "files": [
        "i18n/src/es-ES.js"
      ]
    },
    {
      "code": "pt-PT",
      "iso": "pt-PT",
      "name": "Português",
      "files": [
        "i18n/src/pt-PT.js"
      ]
    },
    {
      "code": "cs-CZ",
      "iso": "cs-CZ",
      "name": "Čeština",
      "files": [
        "i18n/src/cs-CZ.js"
      ]
    },
    {
      "code": "sk-SK",
      "iso": "sk-SK",
      "name": "Slovenčina",
      "files": [
        "i18n/src/sk-SK.js"
      ]
    },
    {
      "code": "hu-HU",
      "iso": "hu-HU",
      "name": "Magyar",
      "files": [
        "i18n/src/hu-HU.js"
      ]
    },
    {
      "code": "pl-PL",
      "iso": "pl-PL",
      "name": "Polski",
      "files": [
        "i18n/src/pl-PL.js"
      ]
    },
    {
      "code": "hr-HR",
      "iso": "hr-HR",
      "name": "Hrvatski",
      "files": [
        "i18n/src/hr-HR.js"
      ]
    },
    {
      "code": "sl-SI",
      "iso": "sl-SI",
      "name": "Slovenščina",
      "files": [
        "i18n/src/sl-SI.js"
      ]
    },
    {
      "code": "lt-LT",
      "iso": "lt-LT",
      "name": "Lietuvių",
      "files": [
        "i18n/src/lt-LT.js"
      ]
    },
    {
      "code": "lv-LV",
      "iso": "lv-LV",
      "name": "Latviešu",
      "files": [
        "i18n/src/lv-LV.js"
      ]
    },
    {
      "code": "fi-FI",
      "iso": "fi-FI",
      "name": "Suomi",
      "files": [
        "i18n/src/fi-FI.js"
      ]
    },
    {
      "code": "da-DK",
      "iso": "da-DK",
      "name": "Dansk",
      "files": [
        "i18n/src/da-DK.js"
      ]
    },
    {
      "code": "no-NO",
      "iso": "no-NO",
      "name": "Norsk",
      "files": [
        "i18n/src/no-NO.js"
      ]
    },
    {
      "code": "bg-BG",
      "iso": "bg-BG",
      "name": "Български",
      "files": [
        "i18n/src/bg-BG.js"
      ]
    },
    {
      "code": "el-GR",
      "iso": "el-GR",
      "name": "Ελληνικά",
      "files": [
        "i18n/src/el-GR.js"
      ]
    },
    {
      "code": "lb-LU",
      "iso": "lb-LU",
      "name": "Luxembourg",
      "files": [
        "i18n/src/fr-FR.js"
      ]
    }
  ],
  "defaultLocale": "de-DE",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": "i18n/src/",
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": false
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {},
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "code": "de-DE",
      "iso": "de-DE",
      "name": "Deutsch",
      "files": [
        {
          "path": "i18n/src/de-DE.js"
        }
      ]
    },
    {
      "code": "en-US",
      "iso": "en-US",
      "name": "English",
      "files": [
        {
          "path": "i18n/src/en-US.js"
        }
      ]
    },
    {
      "code": "fr-FR",
      "iso": "fr-FR",
      "name": "Français",
      "files": [
        {
          "path": "i18n/src/fr-FR.js"
        }
      ]
    },
    {
      "code": "sv-SE",
      "iso": "sv-SE",
      "name": "Svenska",
      "files": [
        {
          "path": "i18n/src/sv-SE.js"
        }
      ]
    },
    {
      "code": "et-EE",
      "iso": "et-EE",
      "name": "Eesti",
      "files": [
        {
          "path": "i18n/src/et-EE.js"
        }
      ]
    },
    {
      "code": "it-IT",
      "iso": "it-IT",
      "name": "Italiano",
      "files": [
        {
          "path": "i18n/src/it-IT.js"
        }
      ]
    },
    {
      "code": "nl-NL",
      "iso": "nl-NL",
      "name": "Nederlands",
      "files": [
        {
          "path": "i18n/src/nl-NL.js"
        }
      ]
    },
    {
      "code": "es-ES",
      "iso": "es-ES",
      "name": "Español",
      "files": [
        {
          "path": "i18n/src/es-ES.js"
        }
      ]
    },
    {
      "code": "pt-PT",
      "iso": "pt-PT",
      "name": "Português",
      "files": [
        {
          "path": "i18n/src/pt-PT.js"
        }
      ]
    },
    {
      "code": "cs-CZ",
      "iso": "cs-CZ",
      "name": "Čeština",
      "files": [
        {
          "path": "i18n/src/cs-CZ.js"
        }
      ]
    },
    {
      "code": "sk-SK",
      "iso": "sk-SK",
      "name": "Slovenčina",
      "files": [
        {
          "path": "i18n/src/sk-SK.js"
        }
      ]
    },
    {
      "code": "hu-HU",
      "iso": "hu-HU",
      "name": "Magyar",
      "files": [
        {
          "path": "i18n/src/hu-HU.js"
        }
      ]
    },
    {
      "code": "pl-PL",
      "iso": "pl-PL",
      "name": "Polski",
      "files": [
        {
          "path": "i18n/src/pl-PL.js"
        }
      ]
    },
    {
      "code": "hr-HR",
      "iso": "hr-HR",
      "name": "Hrvatski",
      "files": [
        {
          "path": "i18n/src/hr-HR.js"
        }
      ]
    },
    {
      "code": "sl-SI",
      "iso": "sl-SI",
      "name": "Slovenščina",
      "files": [
        {
          "path": "i18n/src/sl-SI.js"
        }
      ]
    },
    {
      "code": "lt-LT",
      "iso": "lt-LT",
      "name": "Lietuvių",
      "files": [
        {
          "path": "i18n/src/lt-LT.js"
        }
      ]
    },
    {
      "code": "lv-LV",
      "iso": "lv-LV",
      "name": "Latviešu",
      "files": [
        {
          "path": "i18n/src/lv-LV.js"
        }
      ]
    },
    {
      "code": "fi-FI",
      "iso": "fi-FI",
      "name": "Suomi",
      "files": [
        {
          "path": "i18n/src/fi-FI.js"
        }
      ]
    },
    {
      "code": "da-DK",
      "iso": "da-DK",
      "name": "Dansk",
      "files": [
        {
          "path": "i18n/src/da-DK.js"
        }
      ]
    },
    {
      "code": "no-NO",
      "iso": "no-NO",
      "name": "Norsk",
      "files": [
        {
          "path": "i18n/src/no-NO.js"
        }
      ]
    },
    {
      "code": "bg-BG",
      "iso": "bg-BG",
      "name": "Български",
      "files": [
        {
          "path": "i18n/src/bg-BG.js"
        }
      ]
    },
    {
      "code": "el-GR",
      "iso": "el-GR",
      "name": "Ελληνικά",
      "files": [
        {
          "path": "i18n/src/el-GR.js"
        }
      ]
    },
    {
      "code": "lb-LU",
      "iso": "lb-LU",
      "name": "Luxembourg",
      "files": [
        {
          "path": "i18n/src/fr-FR.js"
        }
      ]
    }
  ]
}
 
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
